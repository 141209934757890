<template>
    <v-dialog
        :key="pageKey"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ $t('message.updatePageSetup') + ": " + helpPageTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form
                    lazy-validation
                    ref="helpPageSetupForm"
                    v-model="validForm"
                >
                    <v-layout row pt-3>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.title') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-text-field
                                :value="HelpPage__title"
                                :rules="[...validationRules.plainTextWithNumberAndSymbols, ...validationRules.required]"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                @change="HelpPage__title = $event"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.relatedComponent') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-autocomplete
                                :items="components"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="HelpPage__component"
                            ></v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.tocGroup') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-select
                                :items="groups"
                                :placeholder="$t('message.levelOne')"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="groupLevels.first"
                                @change="updateGroup()"
                            ></v-select>
                            <v-select
                                :items="groups"
                                :placeholder="$t('message.levelTwo')"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="groupLevels.second"
                                @change="updateGroup()"
                            ></v-select>
                            <v-select
                                :items="groups"
                                :placeholder="$t('message.levelThree')"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="groupLevels.third"
                                @change="updateGroup()"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.sortId') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-text-field
                                :value="HelpPage__sort_id"
                                :rules="[...validationRules.number, ...validationRules.required]"
                                autocomplete="off"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                @change="HelpPage__sort_id = $event"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.abilityFilter') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-autocomplete
                                :items="abilityFilters"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="HelpPage__ability_filter"
                            ></v-autocomplete>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="saveHelpPageSetup()"
                >
                    {{ $t('message.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {log} from "Helpers/helpers";

export default {
    name: "HelpPageSetup",
    props: ['helpPageId','helpPageTitle','dialog','pageKey','updateMode'],
    data() {
        return {
            abilityFilters: [],
            components: [],
            groups: [],
            groupLevels: {
                first: null,
                second: null,
                third: null
            },
            edit_dialog: false,
            loading: {
                get: false,
                save: false
            },
            // rules: {
            //     title: v => !!v || this.$t('message.required'),
            //     sort: v => !!v || this.$t('message.required')
            // },
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('help',{
            HelpPage__id: 'HelpPage.id',
            HelpPage__title: 'HelpPage.title',
            HelpPage__component: 'HelpPage.component',
            HelpPage__group: 'HelpPage.group',
            HelpPage__sort_id: 'HelpPage.sort_id',
            HelpPage__ability_filter: 'HelpPage.ability_filter'
        },'statePrefix'),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('help', {
            getHelpPageSetupOptions: 'getHelpPageSetupOptions',
            updateHelpPageSetup: 'updateHelpPageSetup'
        }),
        updateGroup () {
            this.HelpPage__group = [this.groupLevels.first,this.groupLevels.second, this.groupLevels.third].filter(Boolean)
        },
        saveHelpPageSetup () {
            if (this.$refs.helpPageSetupForm.validate()) {
                this.loading.save = true
                this.updateHelpPageSetup()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.helpPageSetupUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.helpPageSetupNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.helpPageSetupNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            }
        }
    },
    watch: {
        dialog(value) {
            this.edit_dialog = value
            if(value){
                this.groupLevels.first = this.HelpPage__group[0]
                this.groupLevels.second = this.HelpPage__group[1]
                this.groupLevels.third = this.HelpPage__group[2]
            } else {
                this.groupLevels.first = null
                this.groupLevels.second = null
                this.groupLevels.third = null
            }
        }
    },
    mounted() {
        this.getHelpPageSetupOptions()
            .then((options) => {
                this.abilityFilters = options.abilityFilters
                this.components = options.components
                this.groups = options.groups
            })
    }
}
</script>

<style scoped>

</style>